import { Injectable } from '@angular/core';

@Injectable( {
  providedIn: 'root'
} )
export class StorageService {
  protected storage = localStorage;
  commercialGetter: any;

  constructor() {
  }

  get<T = any>( key: string ): string | null | T {
    if ( this.storage.getItem( key ) ) {
      try {
        return JSON.parse( this.storage.getItem( key ) as string ) as T;
      } catch ( e ) {
        return this.storage.getItem( key );
      }
    } else {
      return null;
    }
  }

  set( key: string, data: any, parse = true ): void {
    this.storage.setItem( key, parse ? JSON.stringify( data ) : data );
  }

  remove( key: string ) {
    this.storage.removeItem( key );
  }

  removeListKeys( listKeys: string[] ) {
    listKeys.forEach( ( key: string ) => {
      this.remove( key );
    } );
  }

  deleteAllLocalStorage() {
    const items = Object.keys( localStorage );
    items.forEach( ( key ) => {
      this.remove( key );
    } );
  }
}
