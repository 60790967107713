<ngb-toast *ngFor="let toast of toastService.toasts" [class]="toast.classname" [autohide]="true"
    [delay]="toast.delay || 15000" (hidden)="toastService.remove(toast)">

    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>
        <span *ngIf="toast.icon">
            <i class="{{toast.icon}}"></i>
        </span>
        {{ toast.textOrTpl }}
    </ng-template>
</ngb-toast>
